// ../../../react-shim.js
import React from "react";

// src/menu-button.tsx
import { forwardRef, chakra } from "@chakra-ui/system";

// ../../utilities/shared-utils/src/index.ts
var cx = (...classNames) => classNames.filter(Boolean).join(" ");
function runIfFn(valueOrFn, ...args) {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}
var isFunction = (value) => typeof value === "function";
var dataAttr = (condition) => condition ? "" : void 0;
function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn == null ? void 0 : fn(event);
      return event == null ? void 0 : event.defaultPrevented;
    });
  };
}
function callAll(...fns) {
  return function mergedFn(arg) {
    fns.forEach((fn) => {
      fn == null ? void 0 : fn(arg);
    });
  };
}

// src/menu.tsx
import { createContext as createContext2 } from "@chakra-ui/react-context";
import {
  omitThemingProps,
  useMultiStyleConfig,
  useTheme
} from "@chakra-ui/system";
import { useMemo as useMemo2 } from "react";

// src/use-menu.ts
import { useClickable } from "@chakra-ui/clickable";
import { createDescendantContext } from "@chakra-ui/descendant";
import { useFocusOnHide } from "@chakra-ui/react-use-focus-effect";
import { usePopper } from "@chakra-ui/popper";
import {
  useDisclosure
} from "@chakra-ui/react-use-disclosure";
import { useOutsideClick } from "@chakra-ui/react-use-outside-click";
import { useAnimationState } from "@chakra-ui/react-use-animation-state";
import { createContext } from "@chakra-ui/react-context";
import { getValidChildren } from "@chakra-ui/react-children-utils";
import { useControllableState } from "@chakra-ui/react-use-controllable-state";
import { useUpdateEffect } from "@chakra-ui/react-use-update-effect";
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";
import { lazyDisclosure } from "@chakra-ui/lazy-utils";
import {
  cloneElement,
  useCallback,
  useRef as useRef2,
  useState as useState2,
  useId,
  useMemo,
  useEffect as useEffect2
} from "react";

// src/use-shortcut.ts
import { useEffect, useRef, useState } from "react";
function isPrintableCharacter(event) {
  const { key } = event;
  return key.length === 1 || key.length > 1 && /[^a-zA-Z0-9]/.test(key);
}
function useShortcut(props = {}) {
  const { timeout = 300, preventDefault = () => true } = props;
  const [keys, setKeys] = useState([]);
  const timeoutRef = useRef();
  const flush = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };
  const clearKeysAfterDelay = () => {
    flush();
    timeoutRef.current = setTimeout(() => {
      setKeys([]);
      timeoutRef.current = null;
    }, timeout);
  };
  useEffect(() => flush, []);
  function onKeyDown(fn) {
    return (event) => {
      if (event.key === "Backspace") {
        const keysCopy = [...keys];
        keysCopy.pop();
        setKeys(keysCopy);
        return;
      }
      if (isPrintableCharacter(event)) {
        const keysCopy = keys.concat(event.key);
        if (preventDefault(event)) {
          event.preventDefault();
          event.stopPropagation();
        }
        setKeys(keysCopy);
        fn(keysCopy.join(""));
        clearKeysAfterDelay();
      }
    };
  }
  return onKeyDown;
}

// src/get-next-item-from-search.ts
function getNextItemFromSearch(items, searchString, itemToString, currentItem) {
  if (searchString == null) {
    return currentItem;
  }
  if (!currentItem) {
    const foundItem = items.find((item) => itemToString(item).toLowerCase().startsWith(searchString.toLowerCase()));
    return foundItem;
  }
  const matchingItems = items.filter((item) => itemToString(item).toLowerCase().startsWith(searchString.toLowerCase()));
  if (matchingItems.length > 0) {
    let nextIndex;
    if (matchingItems.includes(currentItem)) {
      const currentIndex = matchingItems.indexOf(currentItem);
      nextIndex = currentIndex + 1;
      if (nextIndex === matchingItems.length) {
        nextIndex = 0;
      }
      return matchingItems[nextIndex];
    }
    nextIndex = items.indexOf(matchingItems[0]);
    return items[nextIndex];
  }
  return currentItem;
}

// src/use-menu.ts
var [
  MenuDescendantsProvider,
  useMenuDescendantsContext,
  useMenuDescendants,
  useMenuDescendant
] = createDescendantContext();
var [MenuProvider, useMenuContext] = createContext({
  strict: false,
  name: "MenuContext"
});
function useIds(idProp, ...prefixes) {
  const reactId = useId();
  const id = idProp || reactId;
  return useMemo(() => {
    return prefixes.map((prefix) => `${prefix}-${id}`);
  }, [id, prefixes]);
}
function getOwnerDocument(node) {
  return (node == null ? void 0 : node.ownerDocument) ?? document;
}
function isActiveElement(element) {
  const doc = getOwnerDocument(element);
  return doc.activeElement === element;
}
function useMenu(props = {}) {
  const {
    id,
    closeOnSelect = true,
    closeOnBlur = true,
    autoSelect = true,
    isLazy,
    isOpen: isOpenProp,
    defaultIsOpen,
    onClose: onCloseProp,
    onOpen: onOpenProp,
    placement = "bottom-start",
    lazyBehavior = "unmount",
    direction,
    computePositionOnMount = false,
    ...popperProps
  } = props;
  const menuRef = useRef2(null);
  const buttonRef = useRef2(null);
  const descendants = useMenuDescendants();
  const focusMenu = useCallback(() => {
    requestAnimationFrame(() => {
      var _a;
      (_a = menuRef.current) == null ? void 0 : _a.focus({ preventScroll: false });
    });
  }, []);
  const focusFirstItem = useCallback(() => {
    const id2 = setTimeout(() => {
      const first = descendants.firstEnabled();
      if (first)
        setFocusedIndex(first.index);
    });
    timeoutIds.current.add(id2);
  }, [descendants]);
  const focusLastItem = useCallback(() => {
    const id2 = setTimeout(() => {
      const last = descendants.lastEnabled();
      if (last)
        setFocusedIndex(last.index);
    });
    timeoutIds.current.add(id2);
  }, [descendants]);
  const onOpenInternal = useCallback(() => {
    onOpenProp == null ? void 0 : onOpenProp();
    if (autoSelect) {
      focusFirstItem();
    } else {
      focusMenu();
    }
  }, [autoSelect, focusFirstItem, focusMenu, onOpenProp]);
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure({
    isOpen: isOpenProp,
    defaultIsOpen,
    onClose: onCloseProp,
    onOpen: onOpenInternal
  });
  useOutsideClick({
    enabled: isOpen && closeOnBlur,
    ref: menuRef,
    handler: (event) => {
      var _a;
      if (!((_a = buttonRef.current) == null ? void 0 : _a.contains(event.target))) {
        onClose();
      }
    }
  });
  const popper = usePopper({
    ...popperProps,
    enabled: isOpen || computePositionOnMount,
    placement,
    direction
  });
  const [focusedIndex, setFocusedIndex] = useState2(-1);
  useUpdateEffect(() => {
    if (!isOpen) {
      setFocusedIndex(-1);
    }
  }, [isOpen]);
  useFocusOnHide(menuRef, {
    focusRef: buttonRef,
    visible: isOpen,
    shouldFocus: true
  });
  const animationState = useAnimationState({ isOpen, ref: menuRef });
  const [buttonId, menuId] = useIds(id, `menu-button`, `menu-list`);
  const openAndFocusMenu = useCallback(() => {
    onOpen();
    focusMenu();
  }, [onOpen, focusMenu]);
  const timeoutIds = useRef2(/* @__PURE__ */ new Set([]));
  useUnmountEffect(() => {
    timeoutIds.current.forEach((id2) => clearTimeout(id2));
    timeoutIds.current.clear();
  });
  const openAndFocusFirstItem = useCallback(() => {
    onOpen();
    focusFirstItem();
  }, [focusFirstItem, onOpen]);
  const openAndFocusLastItem = useCallback(() => {
    onOpen();
    focusLastItem();
  }, [onOpen, focusLastItem]);
  const refocus = useCallback(() => {
    var _a, _b;
    const doc = getOwnerDocument(menuRef.current);
    const hasFocusWithin = (_a = menuRef.current) == null ? void 0 : _a.contains(doc.activeElement);
    const shouldRefocus = isOpen && !hasFocusWithin;
    if (!shouldRefocus)
      return;
    const node = (_b = descendants.item(focusedIndex)) == null ? void 0 : _b.node;
    node == null ? void 0 : node.focus();
  }, [isOpen, focusedIndex, descendants]);
  return {
    openAndFocusMenu,
    openAndFocusFirstItem,
    openAndFocusLastItem,
    onTransitionEnd: refocus,
    unstable__animationState: animationState,
    descendants,
    popper,
    buttonId,
    menuId,
    forceUpdate: popper.forceUpdate,
    orientation: "vertical",
    isOpen,
    onToggle,
    onOpen,
    onClose,
    menuRef,
    buttonRef,
    focusedIndex,
    closeOnSelect,
    closeOnBlur,
    autoSelect,
    setFocusedIndex,
    isLazy,
    lazyBehavior
  };
}
function useMenuButton(props = {}, externalRef = null) {
  const menu = useMenuContext();
  const { onToggle, popper, openAndFocusFirstItem, openAndFocusLastItem } = menu;
  const onKeyDown = useCallback((event) => {
    const eventKey = event.key;
    const keyMap = {
      Enter: openAndFocusFirstItem,
      ArrowDown: openAndFocusFirstItem,
      ArrowUp: openAndFocusLastItem
    };
    const action = keyMap[eventKey];
    if (action) {
      event.preventDefault();
      event.stopPropagation();
      action(event);
    }
  }, [openAndFocusFirstItem, openAndFocusLastItem]);
  return {
    ...props,
    ref: mergeRefs(menu.buttonRef, externalRef, popper.referenceRef),
    id: menu.buttonId,
    "data-active": dataAttr(menu.isOpen),
    "aria-expanded": menu.isOpen,
    "aria-haspopup": "menu",
    "aria-controls": menu.menuId,
    onClick: callAllHandlers(props.onClick, onToggle),
    onKeyDown: callAllHandlers(props.onKeyDown, onKeyDown)
  };
}
function isTargetMenuItem(target) {
  var _a;
  return isHTMLElement(target) && !!((_a = target == null ? void 0 : target.getAttribute("role")) == null ? void 0 : _a.startsWith("menuitem"));
}
function useMenuList(props = {}, ref = null) {
  const menu = useMenuContext();
  if (!menu) {
    throw new Error(`useMenuContext: context is undefined. Seems you forgot to wrap component within <Menu>`);
  }
  const {
    focusedIndex,
    setFocusedIndex,
    menuRef,
    isOpen,
    onClose,
    menuId,
    isLazy,
    lazyBehavior,
    unstable__animationState: animated
  } = menu;
  const descendants = useMenuDescendantsContext();
  const createTypeaheadHandler = useShortcut({
    preventDefault: (event) => event.key !== " " && isTargetMenuItem(event.target)
  });
  const onKeyDown = useCallback((event) => {
    const eventKey = event.key;
    const keyMap = {
      Tab: (event2) => event2.preventDefault(),
      Escape: onClose,
      ArrowDown: () => {
        const next = descendants.nextEnabled(focusedIndex);
        if (next)
          setFocusedIndex(next.index);
      },
      ArrowUp: () => {
        const prev = descendants.prevEnabled(focusedIndex);
        if (prev)
          setFocusedIndex(prev.index);
      }
    };
    const fn = keyMap[eventKey];
    if (fn) {
      event.preventDefault();
      fn(event);
      return;
    }
    const onTypeahead = createTypeaheadHandler((character) => {
      const nextItem = getNextItemFromSearch(descendants.values(), character, (item) => {
        var _a;
        return ((_a = item == null ? void 0 : item.node) == null ? void 0 : _a.textContent) ?? "";
      }, descendants.item(focusedIndex));
      if (nextItem) {
        const index = descendants.indexOf(nextItem.node);
        setFocusedIndex(index);
      }
    });
    if (isTargetMenuItem(event.target)) {
      onTypeahead(event);
    }
  }, [
    descendants,
    focusedIndex,
    createTypeaheadHandler,
    onClose,
    setFocusedIndex
  ]);
  const hasBeenOpened = useRef2(false);
  if (isOpen) {
    hasBeenOpened.current = true;
  }
  const shouldRenderChildren = lazyDisclosure({
    wasSelected: hasBeenOpened.current,
    enabled: isLazy,
    mode: lazyBehavior,
    isSelected: animated.present
  });
  return {
    ...props,
    ref: mergeRefs(menuRef, ref),
    children: shouldRenderChildren ? props.children : null,
    tabIndex: -1,
    role: "menu",
    id: menuId,
    style: {
      ...props.style,
      transformOrigin: "var(--popper-transform-origin)"
    },
    "aria-orientation": "vertical",
    onKeyDown: callAllHandlers(props.onKeyDown, onKeyDown)
  };
}
function useMenuPositioner(props = {}) {
  const { popper, isOpen } = useMenuContext();
  return popper.getPopperProps({
    ...props,
    style: {
      visibility: isOpen ? "visible" : "hidden",
      ...props.style
    }
  });
}
function useMenuItem(props = {}, externalRef = null) {
  const {
    onMouseEnter: onMouseEnterProp,
    onMouseMove: onMouseMoveProp,
    onMouseLeave: onMouseLeaveProp,
    onClick: onClickProp,
    isDisabled,
    isFocusable,
    closeOnSelect,
    type: typeProp,
    ...htmlProps
  } = props;
  const menu = useMenuContext();
  const {
    setFocusedIndex,
    focusedIndex,
    closeOnSelect: menuCloseOnSelect,
    onClose,
    menuRef,
    isOpen,
    menuId
  } = menu;
  const ref = useRef2(null);
  const id = `${menuId}-menuitem-${useId()}`;
  const { index, register } = useMenuDescendant({
    disabled: isDisabled && !isFocusable
  });
  const onMouseEnter = useCallback((event) => {
    onMouseEnterProp == null ? void 0 : onMouseEnterProp(event);
    if (isDisabled)
      return;
    setFocusedIndex(index);
  }, [setFocusedIndex, index, isDisabled, onMouseEnterProp]);
  const onMouseMove = useCallback((event) => {
    onMouseMoveProp == null ? void 0 : onMouseMoveProp(event);
    if (ref.current && !isActiveElement(ref.current)) {
      onMouseEnter(event);
    }
  }, [onMouseEnter, onMouseMoveProp]);
  const onMouseLeave = useCallback((event) => {
    onMouseLeaveProp == null ? void 0 : onMouseLeaveProp(event);
    if (isDisabled)
      return;
    setFocusedIndex(-1);
  }, [setFocusedIndex, isDisabled, onMouseLeaveProp]);
  const onClick = useCallback((event) => {
    onClickProp == null ? void 0 : onClickProp(event);
    if (!isTargetMenuItem(event.currentTarget))
      return;
    if (closeOnSelect ?? menuCloseOnSelect) {
      onClose();
    }
  }, [onClose, onClickProp, menuCloseOnSelect, closeOnSelect]);
  const isFocused = index === focusedIndex;
  const trulyDisabled = isDisabled && !isFocusable;
  useUpdateEffect(() => {
    if (!isOpen)
      return;
    if (isFocused && !trulyDisabled && ref.current) {
      requestAnimationFrame(() => {
        var _a;
        (_a = ref.current) == null ? void 0 : _a.focus();
      });
    } else if (menuRef.current && !isActiveElement(menuRef.current)) {
      menuRef.current.focus();
    }
  }, [isFocused, trulyDisabled, menuRef, isOpen]);
  const clickableProps = useClickable({
    onClick,
    onMouseEnter,
    onMouseMove,
    onMouseLeave,
    ref: mergeRefs(register, ref, externalRef),
    isDisabled,
    isFocusable
  });
  return {
    ...htmlProps,
    ...clickableProps,
    type: typeProp ?? clickableProps.type,
    id,
    role: "menuitem",
    tabIndex: isFocused ? 0 : -1
  };
}
function useMenuOption(props = {}, ref = null) {
  const { type = "radio", isChecked, ...rest } = props;
  const ownProps = useMenuItem(rest, ref);
  return {
    ...ownProps,
    role: `menuitem${type}`,
    "aria-checked": isChecked
  };
}
function useMenuOptionGroup(props = {}) {
  const {
    children,
    type = "radio",
    value: valueProp,
    defaultValue,
    onChange: onChangeProp,
    ...htmlProps
  } = props;
  const isRadio = type === "radio";
  const fallback = isRadio ? "" : [];
  const [value, setValue] = useControllableState({
    defaultValue: defaultValue ?? fallback,
    value: valueProp,
    onChange: onChangeProp
  });
  const onChange = useCallback((selectedValue) => {
    if (type === "radio" && typeof value === "string") {
      setValue(selectedValue);
    }
    if (type === "checkbox" && Array.isArray(value)) {
      const nextValue = value.includes(selectedValue) ? value.filter((item) => item !== selectedValue) : value.concat(selectedValue);
      setValue(nextValue);
    }
  }, [value, setValue, type]);
  const validChildren = getValidChildren(children);
  const clones = validChildren.map((child) => {
    if (child.type.id !== "MenuItemOption")
      return child;
    const onClick = (event) => {
      var _a, _b;
      onChange(child.props.value);
      (_b = (_a = child.props).onClick) == null ? void 0 : _b.call(_a, event);
    };
    const isChecked = type === "radio" ? child.props.value === value : value.includes(child.props.value);
    return cloneElement(child, {
      type,
      onClick,
      isChecked
    });
  });
  return {
    ...htmlProps,
    children: clones
  };
}
function useMenuState() {
  const { isOpen, onClose } = useMenuContext();
  return { isOpen, onClose };
}
function isHTMLElement(el) {
  if (!isElement(el))
    return false;
  const win = el.ownerDocument.defaultView ?? window;
  return el instanceof win.HTMLElement;
}
function isElement(el) {
  return el != null && typeof el == "object" && "nodeType" in el && el.nodeType === Node.ELEMENT_NODE;
}
function useUnmountEffect(fn, deps = []) {
  return useEffect2(() => () => fn(), deps);
}

// src/menu.tsx
var [MenuStylesProvider, useMenuStyles] = createContext2({
  name: `MenuStylesContext`,
  errorMessage: `useMenuStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Menu />" `
});
var Menu = (props) => {
  const { children } = props;
  const styles = useMultiStyleConfig("Menu", props);
  const ownProps = omitThemingProps(props);
  const { direction } = useTheme();
  const { descendants, ...ctx } = useMenu({ ...ownProps, direction });
  const context = useMemo2(() => ctx, [ctx]);
  const { isOpen, onClose, forceUpdate } = context;
  return /* @__PURE__ */ React.createElement(MenuDescendantsProvider, {
    value: descendants
  }, /* @__PURE__ */ React.createElement(MenuProvider, {
    value: context
  }, /* @__PURE__ */ React.createElement(MenuStylesProvider, {
    value: styles
  }, runIfFn(children, { isOpen, onClose, forceUpdate }))));
};
Menu.displayName = "Menu";

// src/menu-button.tsx
var StyledMenuButton = forwardRef((props, ref) => {
  const styles = useMenuStyles();
  return /* @__PURE__ */ React.createElement(chakra.button, {
    ref,
    ...props,
    __css: {
      display: "inline-flex",
      appearance: "none",
      alignItems: "center",
      outline: 0,
      ...styles.button
    }
  });
});
var MenuButton = forwardRef((props, ref) => {
  const { children, as: As, ...rest } = props;
  const buttonProps = useMenuButton(rest, ref);
  const Element = As || StyledMenuButton;
  return /* @__PURE__ */ React.createElement(Element, {
    ...buttonProps,
    className: cx("chakra-menu__menu-button", props.className)
  }, /* @__PURE__ */ React.createElement(chakra.span, {
    __css: { pointerEvents: "none", flex: "1 1 auto", minW: 0 }
  }, props.children));
});
MenuButton.displayName = "MenuButton";

// src/menu-command.tsx
import { chakra as chakra2, forwardRef as forwardRef2 } from "@chakra-ui/system";
var MenuCommand = forwardRef2((props, ref) => {
  const styles = useMenuStyles();
  return /* @__PURE__ */ React.createElement(chakra2.span, {
    ref,
    ...props,
    __css: styles.command,
    className: "chakra-menu__command"
  });
});
MenuCommand.displayName = "MenuCommand";

// src/menu-divider.tsx
import { chakra as chakra3 } from "@chakra-ui/system";
var MenuDivider = (props) => {
  const { className, ...rest } = props;
  const styles = useMenuStyles();
  return /* @__PURE__ */ React.createElement(chakra3.hr, {
    "aria-orientation": "horizontal",
    className: cx("chakra-menu__divider", className),
    ...rest,
    __css: styles.divider
  });
};
MenuDivider.displayName = "MenuDivider";

// src/menu-group.tsx
import { chakra as chakra4, forwardRef as forwardRef3 } from "@chakra-ui/system";
var MenuGroup = forwardRef3((props, ref) => {
  const { title, children, className, ...rest } = props;
  const _className = cx("chakra-menu__group__title", className);
  const styles = useMenuStyles();
  return /* @__PURE__ */ React.createElement("div", {
    ref,
    className: "chakra-menu__group",
    role: "group"
  }, title && /* @__PURE__ */ React.createElement(chakra4.p, {
    className: _className,
    ...rest,
    __css: styles.groupTitle
  }, title), children);
});
MenuGroup.displayName = "MenuGroup";

// src/menu-icon.tsx
import { chakra as chakra5 } from "@chakra-ui/system";
import { Children, cloneElement as cloneElement2, isValidElement } from "react";
var MenuIcon = (props) => {
  const { className, children, ...rest } = props;
  const child = Children.only(children);
  const clone = isValidElement(child) ? cloneElement2(child, {
    focusable: "false",
    "aria-hidden": true,
    className: cx("chakra-menu__icon", child.props.className)
  }) : null;
  const _className = cx("chakra-menu__icon-wrapper", className);
  return /* @__PURE__ */ React.createElement(chakra5.span, {
    className: _className,
    ...rest,
    __css: {
      flexShrink: 0
    }
  }, clone);
};
MenuIcon.displayName = "MenuIcon";

// src/menu-item-option.tsx
import { forwardRef as forwardRef5 } from "@chakra-ui/system";

// src/styled-menu-item.tsx
import { chakra as chakra6, forwardRef as forwardRef4 } from "@chakra-ui/system";
import { useMemo as useMemo3 } from "react";
var StyledMenuItem = forwardRef4((props, ref) => {
  const { type, ...rest } = props;
  const styles = useMenuStyles();
  const btnType = rest.as || type ? type ?? void 0 : "button";
  const buttonStyles = useMemo3(() => ({
    textDecoration: "none",
    color: "inherit",
    userSelect: "none",
    display: "flex",
    width: "100%",
    alignItems: "center",
    textAlign: "start",
    flex: "0 0 auto",
    outline: 0,
    ...styles.item
  }), [styles.item]);
  return /* @__PURE__ */ React.createElement(chakra6.button, {
    ref,
    type: btnType,
    ...rest,
    __css: buttonStyles
  });
});

// src/menu-item-option.tsx
var CheckIcon = (props) => /* @__PURE__ */ React.createElement("svg", {
  viewBox: "0 0 14 14",
  width: "1em",
  height: "1em",
  ...props
}, /* @__PURE__ */ React.createElement("polygon", {
  fill: "currentColor",
  points: "5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039"
}));
var MenuItemOption = forwardRef5((props, ref) => {
  const { icon, iconSpacing = "0.75rem", ...rest } = props;
  const optionProps = useMenuOption(rest, ref);
  return /* @__PURE__ */ React.createElement(StyledMenuItem, {
    ...optionProps,
    className: cx("chakra-menu__menuitem-option", rest.className)
  }, icon !== null && /* @__PURE__ */ React.createElement(MenuIcon, {
    fontSize: "0.8em",
    marginEnd: iconSpacing,
    opacity: props.isChecked ? 1 : 0
  }, icon || /* @__PURE__ */ React.createElement(CheckIcon, null)), /* @__PURE__ */ React.createElement("span", {
    style: { flex: 1 }
  }, optionProps.children));
});
MenuItemOption.id = "MenuItemOption";
MenuItemOption.displayName = "MenuItemOption";

// src/menu-item.tsx
import { forwardRef as forwardRef6 } from "@chakra-ui/system";
var MenuItem = forwardRef6((props, ref) => {
  const {
    icon,
    iconSpacing = "0.75rem",
    command,
    commandSpacing = "0.75rem",
    children,
    ...rest
  } = props;
  const menuitemProps = useMenuItem(rest, ref);
  const shouldWrap = icon || command;
  const _children = shouldWrap ? /* @__PURE__ */ React.createElement("span", {
    style: { pointerEvents: "none", flex: 1 }
  }, children) : children;
  return /* @__PURE__ */ React.createElement(StyledMenuItem, {
    ...menuitemProps,
    className: cx("chakra-menu__menuitem", menuitemProps.className)
  }, icon && /* @__PURE__ */ React.createElement(MenuIcon, {
    fontSize: "0.8em",
    marginEnd: iconSpacing
  }, icon), _children, command && /* @__PURE__ */ React.createElement(MenuCommand, {
    marginStart: commandSpacing
  }, command));
});
MenuItem.displayName = "MenuItem";

// src/menu-list.tsx
import { chakra as chakra7, forwardRef as forwardRef7 } from "@chakra-ui/system";
import { motion } from "framer-motion";
var motionVariants = {
  enter: {
    visibility: "visible",
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.2,
      ease: [0.4, 0, 0.2, 1]
    }
  },
  exit: {
    transitionEnd: {
      visibility: "hidden"
    },
    opacity: 0,
    scale: 0.8,
    transition: {
      duration: 0.1,
      easings: "easeOut"
    }
  }
};
var MenuTransition = chakra7(motion.div);
var MenuList = forwardRef7(function MenuList2(props, ref) {
  var _a;
  const { rootProps, motionProps, ...rest } = props;
  const {
    isOpen,
    onTransitionEnd,
    unstable__animationState: animated
  } = useMenuContext();
  const listProps = useMenuList(rest, ref);
  const positionerProps = useMenuPositioner(rootProps);
  const styles = useMenuStyles();
  return /* @__PURE__ */ React.createElement(chakra7.div, {
    ...positionerProps,
    __css: { zIndex: props.zIndex ?? ((_a = styles.list) == null ? void 0 : _a.zIndex) }
  }, /* @__PURE__ */ React.createElement(MenuTransition, {
    variants: motionVariants,
    initial: false,
    animate: isOpen ? "enter" : "exit",
    __css: { outline: 0, ...styles.list },
    ...motionProps,
    className: cx("chakra-menu__menu-list", listProps.className),
    ...listProps,
    onUpdate: onTransitionEnd,
    onAnimationComplete: callAll(animated.onComplete, listProps.onAnimationComplete)
  }));
});
MenuList.displayName = "MenuList";

// src/menu-option-group.tsx
var MenuOptionGroup = (props) => {
  const { className, title, ...rest } = props;
  const ownProps = useMenuOptionGroup(rest);
  return /* @__PURE__ */ React.createElement(MenuGroup, {
    title,
    className: cx("chakra-menu__option-group", className),
    ...ownProps
  });
};
MenuOptionGroup.displayName = "MenuOptionGroup";
export {
  Menu,
  MenuButton,
  MenuCommand,
  MenuDescendantsProvider,
  MenuDivider,
  MenuGroup,
  MenuIcon,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  MenuProvider,
  useMenu,
  useMenuButton,
  useMenuContext,
  useMenuDescendant,
  useMenuDescendants,
  useMenuDescendantsContext,
  useMenuItem,
  useMenuList,
  useMenuOption,
  useMenuOptionGroup,
  useMenuPositioner,
  useMenuState,
  useMenuStyles
};
